import React, { ReactElement, ReactNode } from "react";
import { useRouter } from "next/router";
import { SerializedStyles } from "@emotion/react/types";

type Input = {
  id?: string;
  style?: SerializedStyles;
  activeStyle?: SerializedStyles;
  children: ReactNode;
  onClickCallback?: () => void;
  href: string;
  as?: string;
  activeRoot?: string;
  dataTest?: string;
  scrollTop?: boolean;
};

const ActiveLink = ({
  id,
  style,
  activeStyle,
  children,
  onClickCallback,
  href,
  as,
  activeRoot,
  dataTest,
  scrollTop = true,
}: Input): ReactElement => {
  // Determine dynamic custom route (/user/:id) vs static route (/login)
  const router = useRouter();
  const displayPathname = as || href;
  const routerPathname = as ? router.asPath : router.pathname;
  let isActive = false;

  if (activeRoot && router.asPath.startsWith(activeRoot)) {
    isActive = true;
  } else if (routerPathname === displayPathname) {
    isActive = true;
  } else if (routerPathname.startsWith(displayPathname)) {
    isActive = true;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (as) {
      router.push(href, as).then(() => {
        if (scrollTop) window.scrollTo(0, 0);
      });
    } else {
      router.push(href).then(() => {
        if (scrollTop) window.scrollTo(0, 0);
      });
    }

    onClickCallback && onClickCallback();
  };

  return (
    <a
      id={id}
      href={displayPathname}
      css={[style, isActive && activeStyle]}
      onClick={handleClick}
      data-test={dataTest}
    >
      {children}
    </a>
  );
};

export default ActiveLink;
